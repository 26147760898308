import React from 'react'
import styled from 'styled-components'

function FindMe() {
    return (
        <div>
            <FindmeSection>
                <Title>Find me on</Title>
                <Thislink>
                <a href="https://www.instagram.com/vamsi.manohar">
                    <h1>Instagram</h1></a>
                    <a href="https://github.com/vamsimanohar9">
                    <h1>Github</h1></a>
                    <a href="https://www.linkedin.com/in/itsmevamsi">
                    <h1>LinkedIn</h1></a>
                </Thislink>
            </FindmeSection>
        </div>
    )
}

const FindmeSection = styled.div `
    background-color: #121212;
    color: white;
    padding-bottom: 20px;
  text-align: center;
  padding-top: 50px;
  padding-top: 100px;
  height: 20vh;

  a{
      text-decoration: none;
      color: white;
  }
`
const Title = styled.div `
    font-size: 40px;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
`
const Thislink = styled.div`
    display: flex;
    justify-content: center;

    h1{
        font-size: 16px;
        display: flex;
        padding-right: 16px;
        padding-top: 30px;
        font-family: "Bona Nova", serif;
    }
`

export default FindMe
