import React from 'react'
import styled from 'styled-components'

function Portfolio() {
    return (
        <div>
            <TopTitle>
            <Title id='portfolio'>
            <h1>My Works</h1>
            <h3>These are all my projects that I have worked on so far.</h3>
            </Title>
            </TopTitle>

            <DisplayPro>
                <FirstRow>
                    <Gridone>
                        <h1>TESLA CLONE</h1>
                        <h2>React</h2>
                        <a href="https://jolly-bardeen-fd7f15.netlify.app/">
                        <button className='NewButton'>Visit Website</button></a>
                    </Gridone>
                    <GridImg>
                        <img src="https://raw.githubusercontent.com/vamsimanohar9/images/main/Screenshot%20(50).png" alt="added" />
                    </GridImg>
                </FirstRow>
                <AltFirstRow>
                <GridImg>
                        <img src="https://raw.githubusercontent.com/vamsimanohar9/images/main/Screenshot%20(49).png" alt="added" />
                    </GridImg>
                    <AltGridone>
                        <h1>AMAZON CLONE</h1>
                        <h2>React</h2>
                        <a href='https://github.com/vamsimanohar9/amazon-clone'>
                        <button className='NewButton'>Visit Website</button></a>
                    </AltGridone>
                    
                </AltFirstRow>
                <FirstRow>
                    <Gridone>
                        <h1>NETFLIX CLONE</h1>
                        <h2>React</h2>
                        <a href="https://fervent-joliot-7d27ab.netlify.app/">
                        <button className='NewButton'>Visit Website</button></a>
                    </Gridone>
                    <GridImg>
                        <img src="https://raw.githubusercontent.com/vamsimanohar9/images/main/Screenshot%20(47).png" alt="added" />
                    </GridImg>
                </FirstRow>
                <AltFirstRow>
                <GridImg>
                        <img src="https://raw.githubusercontent.com/vamsimanohar9/images/main/Screenshot%20(53).png" alt="added" />
                    </GridImg>
                    <AltGridone>
                        <h1>PORTFOLIO WEBSITE</h1>
                        <h2>HTML,CSS,JS</h2>
                        <a href="https://stoic-shockley-e1dd9f.netlify.app/">
                        <button className='NewButton'>Visit Website</button></a>
                    </AltGridone>
                    
                </AltFirstRow>
            </DisplayPro>
        </div>
    )
}

const TopTitle = styled.div `

`
const Title = styled.div `
background-color: #121212;
color: white;
padding-left: 3rem;
 padding-top: 4rem;
    font-size: 1.5rem;
    @media (max-width: 768px) {
    padding-top: 50px;
    padding-left:2rem ;
    padding-right: 0px;
  }
    h1{
        font-family: 'Nunito', sans-serif;
    }
    h3{
        font-size: 1rem;
        padding-top: 20px;
        font-family: 'Poppins', sans-serif;
    }
`

const DisplayPro = styled.div `
    color: white;
    background-color: #121212;
    padding-top: 5rem;
    @media (max-width: 768px) {
    padding-top: 0;
  }
`
const Gridone = styled.div `
width: 480px;
margin-top: 20px;
    h1{
        font-family: 'Nunito', sans-serif;
    }
    h2{
        font-family: 'Poppins', sans-serif;
    }
.NewButton{
        width: 120px;
        height: 40px;
        outline: none;
        border: none;
        background-color: white;
        border-radius: 30px;
        margin-top: 15px;
        font-family: 'Poppins', sans-serif;
        cursor: pointer;
    }

`
const GridImg = styled.div `
    img{
        width: 480px;
    }
    @media (max-width:768px){
        img{
            width: 80%;
        }
    }

`
const FirstRow = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    text-align: center;
    margin-top: 3rem;
    @media (max-width: 768px) {
    margin-top:0 ;
    padding-top:20px ;
  }
    
`

const AltGridone = styled.div`
width: 480px;
padding-top: 20px;
text-align: center;

    @media(max-width:768px){
        width:100%;
    }
    h1{
        font-family: 'Nunito', sans-serif;
    }
.NewButton{
        width: 120px;
        height: 40px;
        outline: none;
        border: none;
        background-color: white;
        border-radius: 30px;
        margin-top: 15px;
        font-family: 'Poppins', sans-serif;  
        cursor: pointer;
    }
`
    const AltFirstRow = styled.div `
     display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    text-align: center;
    margin-top: 3rem;

    @media (max-width:768px){
        display: flex;
        flex-direction: column-reverse;
    }
    `

export default Portfolio
