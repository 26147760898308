import React from 'react'
import styled from 'styled-components'
import { GrInstagram,GrLinkedin,GrGithub,GrMail } from 'react-icons/gr';
import Fade from 'react-reveal/Fade';

function Hero() {
    return (
        <div>
            <Homepage id='home'>
                <LeftSide>
                    <TitleName>
                        <h1>Vamsi Manohar</h1>
                    </TitleName>
                    <AnimationText>
                        <h2>FrontEnd developer</h2>
                    </AnimationText>
                    <Buttons>
                    <a href="#portfolio">
                        <button className='NewButton'>Portfolio</button></a>
                        <a href="https://drive.google.com/file/d/1jfr4_NAE6M8CGQKWuT4iYJd1pUO-WntQ/view?usp=sharing">
                        <button className='NewButton'>Resume</button></a>
                    </Buttons>
                        <SocialMedia>
                            <a href="https://www.instagram.com/vamsi.manohar/">
                            <GrInstagram className='SocialLinks' /></a>
                            <a href="https://www.linkedin.com/in/itsmevamsi/">
                            <GrLinkedin className='SocialLinks'/></a>
                            <a href="https://github.com/vamsimanohar9">
                            <GrGithub className='SocialLinks'/></a>
                            <a href="mailto:vamsimonohar@gmail.com">
                            <GrMail className='SocialLinks'/></a>

                        </SocialMedia>
                </LeftSide>
                <RightSide>
                    <ProfileImage>
                    <img className='bill' src="https://i.ibb.co/gDVPcr1/Vamsi-Manohar-Avatar.png" alt="working" />
                    </ProfileImage>
                </RightSide>
            </Homepage>
        </div>
    )
}

const Homepage = styled.div `
color: white;
display: flex;
align-items: center;
justify-content: space-around;
flex-wrap: wrap;
background-color: #121212;
height: 100vh;
@media (max-width: 768px) {
    height: auto;
  }
`
const LeftSide = styled.div`
@media (max-width: 768px) {
    margin-top: 50px ;
  }
    
`
const RightSide = styled.div `
    
`
const TitleName = styled.div `
    color: white;
    font-size: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    
    h1{
        font-family: 'Nunito', sans-serif;
    }
`
const AnimationText = styled.div `
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
`
const Buttons = styled.div `
    display: flex;
    gap: 10px;
    justify-content: center;
    padding-top: 4rem;

    .NewButton{
        width: 120px;
        height: 40px;
        outline: none;
        border: none;
        background-color: white;
        border-radius: 30px;
        font-family: 'Poppins', sans-serif;
        cursor: pointer;
        
    }
`
const SocialMedia = styled.div `
    display: flex;
    justify-content: center;
    color: white;
    font-size: 20px;
    padding-top: 5rem;
    gap: 5rem;
    text-decoration: none;
    .SocialLinks{
        text-decoration: none;
        color: white;
    }
`
const ProfileImage = styled.div `
    .bill{
        max-width: 100%;
        height: auto;
    }
    @media (max-width:768px){
        display: none;
    }

`



export default Hero
