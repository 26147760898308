import firebase from "firebase";

var firebaseConfig = {
    apiKey: "AIzaSyCByipPl53sU9kXcdflHE9FDyvx25JpXns",
    authDomain: "contact-information-ab87d.firebaseapp.com",
    projectId: "contact-information-ab87d",
    storageBucket: "contact-information-ab87d.appspot.com",
    messagingSenderId: "263303158127",
    appId: "1:263303158127:web:9dd46561b2b4891455309a"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  var db = firebase.firestore();

  export {db};