import './App.css';
import Navbar from './Navbar';
import styled from 'styled-components'
import Hero from './Hero';
import About from './About';
import Skills from './skills';
import Portfolio from './Portfolio';
import ContactUs from './ContactUs';
import FindMe from './FindMe';
import Footer from './Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    
      <Container >
      <Navbar />
      <Hero />
      <About />
      <Skills/>
      <Portfolio />
      <ContactUs />
      <FindMe />
      <Footer />
      </Container>
  );
}

const Container = styled.div`
  background-color: #121212;
  height: 100vh;
`

export default App;
