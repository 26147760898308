import React, {useState} from 'react'
import styled from 'styled-components'

function Navbar() {
const [isOpen,SetisOpen] = useState(false)

    return (
        <Nav>
            <Logo href="">
            <img src="https://raw.githubusercontent.com/vamsimanohar9/images/main/VM%20(2).png" alt="logo" />
            </Logo>
            <Hamberger onClick={()=>SetisOpen(!isOpen)}>
                <span />
                <span />
                <span />
            </Hamberger>
            <Menu isOpen={isOpen}>

                <MenuLink href='#home'>Home</MenuLink>
                <MenuLink href='#about'>About</MenuLink>
                <MenuLink href='#skills'>Skills</MenuLink>
                <MenuLink href='#portfolio'>Portfolio</MenuLink>
                <MenuLink href='#contact'>Contact</MenuLink>
            </Menu>
        </Nav>
    )
}

const Nav = styled.div `
    color: white;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: #121212;
`

const Hamberger = styled.div `
    display: none;
    flex-direction: column;
    cursor: pointer;
    color: white;

    span{
        
        height: 2px;
        width: 25px;
        background:white;
        margin-bottom: 4px;
        border-radius: 5px;
    }
    @media (max-width:768px){
        display: flex;
    }
`

const Menu = styled.div `
    display: flex;
    justify-content:space-between;
    align-items: center;
    position: relative;

    @media (max-width:768px){
        overflow: hidden;
        flex-direction: column;
        width: 100%;
        max-height: ${({isOpen})=>(isOpen? "300px" : "0")};
        transition: max-height 0.3s ease-in;
    }
`

const Logo = styled.a `
    padding: 1rem 0;
    
    text-decoration: none;
    font-weight: 800;
    font-size: 1.7rem;
    color: white;
`

const MenuLink = styled.a `
    padding: 1rem 2rem;
    cursor: pointer;
    text-align:center;
    text-decoration:none ;
    
    transition: all 0.3s ease-in;
    font-size: 0.9rem;
    color: white;
`


export default Navbar
