import React, { useState } from 'react'
import './ContactUs.css'
import {db} from './Firebase'
import { FiPhoneCall,FiMail } from 'react-icons/fi';



function ContactUs() {
    const [name,setName]= useState("")
    const [email,setEmail]= useState("")
    const [message,setMessage]= useState("")

    const [loader,setLoader] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoader(true)

        db.collection('contacts').add({
            name:name,
            email:email,
             message:message,
        }).then(()=>{
            alert("Thank you, Message sent :) ")
            setLoader(false)
        }).catch((error)=>{
            alert(error.message);
            setLoader(false)
        })

        setName('')
        setEmail('')
        setMessage('')
    }
    return (
        <div>
            <form onSubmit={handleSubmit} className='form' id='contact'>
             <div className="upperclass">
                <h1 className='uppertitleone'>Contact</h1>
                <h2 className='uppertitletwo'>Say Hi! or contact me on social media</h2>
                </div>
                <div className="contact-details">
                    <div className="callnumber">
                        <div className="callicon">
                            <FiPhoneCall />
                        </div>
                        <div className="setofinfo">
                            <h1 id='GiveMe'>Give me a Call</h1>
                            <h2>+91 8309201359</h2>
                        </div>
                    </div>
                    <div className="callnumber">
                        <div className="callicon">
                            <FiMail />
                        </div>
                        <div className="setofinfo">
                            <h1>Get in online</h1>
                            <h2>vamsimonohar@gmail.com</h2>
                        </div>
                    </div>
                </div>
                <div className="textfields"></div>
                <input placeholder='Name' value={name} onChange={(e)=>setName(e.target.value)}/>
                <input placeholder='Email' type='email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
                <textarea placeholder='Message' value={message} onChange={(e)=>setMessage(e.target.value)}></textarea>
                <button type='submit' className='sendbutton'>Send</button>
            </form>
        </div>
    )
}

export default ContactUs
