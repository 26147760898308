import React from 'react'
import styled from 'styled-components'
import { BsArrowRightShort } from 'react-icons/bs';

function About() {
    return (
        <AboutSection id='about'>
            <AboutImage src="https://raw.githubusercontent.com/vamsimanohar9/images/main/20191116050422_IMG_2429-01.jpeg" />
            
            <AboutInfo>
                <AboutHeader className='headerabout'><h1>About</h1></AboutHeader>
                <AboutPara><p>A Computer Science Student, Nano degree holder from Udacity, I'm quietly confident, naturally curious, and perpetually working on improving my chops one design problem at a time..</p></AboutPara>
            </AboutInfo>
            <AboutHire>
                <HireTitle><h3>Let's collaborate!</h3></HireTitle>
                <HirePara><p>I'm open for collaborations. I can made for you great web designs, portfolios, landing pages and much others, Looking to join a talented team as
a Developer </p></HirePara>
                <a href="#contact">
                <HireMe>Hire me <BsArrowRightShort className='arrowbs' /> </HireMe></a>
            </AboutHire>
        </AboutSection>
    )
}

const AboutSection = styled.div `
    color: white;
    background-color: #121212;
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    @media (max-width:990px){
        flex-direction: column;
        margin-top:3rem ;
    }
    a{
        text-decoration: none;
    }
`
const AboutImage = styled.img `
object-fit: cover;
 max-width: 25%;
 height: auto;
 border-radius: 1rem;
 margin-top: 3rem;
 box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 5px 20px 0px rgb(0 0 0 / 40%);
 margin-left: 5rem;
 @media (max-width:990px){
        min-width: 90%;
        padding: 20px;
        margin-top:0;
        margin-left: 0;
        
    }
`

const AboutInfo = styled.div `
padding: 2rem;
margin-top: 10rem;
width: 500px;
@media (max-width:990px){
        width: 100%;
        padding: 0;
        margin-top: 10px;
    }
`

const AboutHire = styled.div `
padding: 2rem;
margin-top: 11rem;
width: 500px;
@media (max-width:990px){
        width: 100%;
        padding: 0;
        margin-top:50px ;
    }


`
const AboutHeader = styled.div `
font-size: 25px;
    h1{
        font-family: 'Nunito', sans-serif;
    }

`
const AboutPara = styled.div `
 font-size: 16px;
 padding-top: 15px;
    p{
        font-family: 'Poppins', sans-serif;
    }
`
const HireTitle = styled.div `
font-size: 25px;
    h3{
        font-family: 'Nunito', sans-serif;
    }
`
const HirePara = styled.div `
font-size: 16px;
padding-top: 15px;
    p{
        font-family: 'Poppins', sans-serif;
    }
`
const HireMe = styled.div `
    cursor: pointer;
    padding-top: 15px;
    color: rgb(255, 215, 0);
`


export default About
