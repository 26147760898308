import React from 'react'
import styled from 'styled-components'
import { BsArrowRightShort } from 'react-icons/bs';

function Skills() {
    return (
        <div>
        <MainSkill id='skills'>
            <SkillTitle>
                <h1>My Skills</h1>
                <h3>Here it's my services that can i do, my skills and my experience.</h3>
                
            </SkillTitle>
            <SkillGrid>
                <Skillone>
                    <TitlePlacement>
                        <ColTitle>
                        <h1>Front-End Developer</h1>
                        <SideScore><h1>Very Good</h1></SideScore>
                        </ColTitle>
                        <h3>2 years experience</h3>
                        <p>I like to code things from scratch, and enjoy bringing ideas to life in the browser.</p>
                        <p className='tools'>HTML, CSS, JS, REACT, WORDPRESS</p>
                    </TitlePlacement>
                </Skillone>
                <Skilltwo>
                <TitlePlacement>
                        <ColTitle>
                        <h1>Video Editing & 2D Animations</h1>
                        <SideScore><h1>Very Good</h1></SideScore>
                        </ColTitle>
                        <h3>2 years experience</h3>
                        <p>I like to bring creative ideas to the table, and create content in a way that help the audience understand better.</p>
                        <p className='tools'>Adobe After Effects, Adobe Premiere Pro, Filmora</p>
                    </TitlePlacement>
                </Skilltwo>
                <Skillthree>
                <TitlePlacement>
                        <ColTitle>
                        <h1>UI/UX Design</h1>
                        <SideScore><h1>Good</h1></SideScore>
                        </ColTitle>
                        <h3>2 years experience</h3>
                        <p>I aim to create simple and clean ui that let's the user feel at ease while using the service</p>
                        <p className='tools'>Figma,Adobe XD, Adobe Photoshop, Adobe Illustrator, Canva</p>
                    </TitlePlacement>
                </Skillthree>
            </SkillGrid>
        </MainSkill>
        </div>
    )
}

const MainSkill = styled.div`
    color: white;
    background-color: #121212;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    padding: 3rem;
    justify-content: center;
    @media (min-width:990px){
        grid-template-columns: 30% 70% ;
        
    }
    @media (max-width: 768px) {
    padding: 2rem ;
  }
    a{
        text-decoration: none;
    }
    
`
const SkillTitle = styled.div`
    padding-top: 4rem;
    font-size: 1.5rem;
    @media (max-width: 768px) {
    padding-top:0 ;
  }
    h3{
        font-size: 1rem;
        padding-top: 10px;
        font-family: 'Poppins', sans-serif;
    }

    h1{
        font-family: 'Nunito', sans-serif;
    }
`
const SkillGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2rem;
    grid-template-rows:250px;
    grid-row-gap: 4rem;
    color: #fff;
    margin-right: 20px;
    @media (min-width:990px){
        grid-template-columns: 50% 50%;
    }
    
`
const Skillone = styled.div`
    background-image:linear-gradient(320deg, #1c1c1f, #d0ae9a);
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.5rem;
    height: auto;
    @media (max-width:768px){
        height: 300px;
        display: grid;
        padding-bottom: 50px;
        margin-bottom: 60px;
        @media (max-width: 768px) {
            padding-bottom: 50px;
            margin-top: 40px;
            
  }
    }


`
const Skilltwo = styled.div`
background-image:linear-gradient(320deg, #1c1c1f, #d0ae9a);
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.5rem;
    height: auto;
    @media (max-width: 768px) {
    margin-top: 140px;
  }

`
const Skillthree = styled.div`
background-image: linear-gradient(320deg, rgb(28, 28, 31), rgb(114, 117, 122));
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.5rem;
    height: auto;

`

const TitlePlacement = styled.div`
    margin-left: 2rem;
    padding-top: 50px;
    h1{
        font-size: 19px;
    }
    h3{
        font-size: 12px;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        color: #ccc;
    }
    p{
        width: 90%;
        font-size: 1rem;
        margin-bottom: 2rem;
        font-family: 'Poppins', sans-serif;
    }
    .tools{
        font-size: 1rem;
        margin-bottom: 2rem;
        width: 100%;
    }
`
const ColTitle = styled.div `
    display: flex;
    justify-content: space-between;

        h1{
            font-family: 'Nunito', sans-serif;
        }

`
const SideScore = styled.div `
margin-right:1.5rem;
background-color: #5d5d5d;
width: 100px;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
h1{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
}
`
const HireMe = styled.div `
    font-size: 16px;
    cursor: pointer;
    padding-top: 15px;
    color: rgb(255, 215, 0);
`


export default Skills
